/* eslint-disable @nx/enforce-module-boundaries */
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PostBoxContext } from '../context';
import {
  Avatar,
  AvatarGroup,
  Box,
  Circle,
  Flex,
  HStack,
  Text,
} from '@mybridge/ui';
import { ChatOutLined, CloseIcon, HandShakeSimpleIcon, SimpleCloseIcon, SuggestedPostIcon } from '@mybridge/icons';
import {
  HandShakeIcon,
  CommentsIcon,
  PinnedIcon,
  SuggestedIcon,
  RepostedIcon,
  QuotedIcon,
} from '@mybridge/icons/DashboardNewICons';

import { ShareIcon } from '@mybridge/icons/Share';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import Link from 'next/link';
import { MyNetworkContext } from 'v4/components/network/context';
import { InsightPopover } from '../insight-popover';
import _ from 'lodash';
import { Divider, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
export const PostActivityHeader = forwardRef(
  ({ maxUserCount = 3, maxCount = 15, post, ...props }, ref) => {
    const { post: ps } = useContext(PostBoxContext);
    const router = useRouter()
    const { myFirstDegreeConnections, 
      refetchMyFirstDegreeConnections,
      isFriend,
      handshakeUser,
      followUser,
      isHandshakeRequested,
      isFollowed,
      handshakeLoading,
      handleHandshakeSendRevoke, 
    } =
      useContext(MyNetworkContext);

      const [loadingButtonId, setLoadingButtonId] = useState(null);

      // Modify the handshake handler
      const handleHandshake = async (user) => {
        setLoadingButtonId(user.id);
        try {
          await handshakeUser?.(user);
        } finally {
          setLoadingButtonId(null);
        }
      };
      
    const currentUserInfo = useSelector(state=> state?.userProfile?.userProfileInfo);
    
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { pinned } = props;
    const {
      comment_count,
      repost,
      like_count,
      dislike_count,
      liked_by,
      dislike_by,
      reposts_by,
      comment_by,
    } = ps ?? {};

    const likedBy = liked_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (liked_by?.length > maxCount) {
      likedBy.push(<Box>and {liked_by?.length - maxCount} more</Box>);
    }
    const dislikedBy = dislike_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (dislike_by?.length > maxCount) {
      dislikedBy.push(<Box>and {dislike_by?.length - maxCount} more</Box>);
    }
    const repostBy = _.uniqBy(reposts_by ?? [], (e) => e.id)
      ?.filter?.((_, ind) => ind < maxCount)
      .map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (reposts_by?.length > maxCount) {
      repostBy.push(<Box>and {reposts_by?.length - maxCount} more</Box>);
    }

    const commentBy = comment_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (comment_by?.length > maxCount) {
      commentBy.push(<Box>and {comment_by?.length - maxCount} more</Box>);
    }

    const sharedBy = reposts_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (reposts_by?.length > maxCount) {
      sharedBy.push(<Box>and {reposts_by?.length - maxCount} more</Box>);
    }

    const map = useMemo(() => {
      const post_ = post ?? ps ?? {};
      return [
        {
          append: ` Quoted post`,
          icon: <QuotedIcon color="#5B5B5B" />,
          count:
            post_.repost !== null && post_.content && post_.content !== ''
              ? Infinity
              : -Infinity,
          users: post_?.author,
          extra: Math.max((post_?.comment_by?.length ?? 0) - maxUserCount, 0),
          content: commentBy,
        },
        {
          // append: `${post_.comment_count >10 ? "+" + post_.comment_count/10 : "+" + post_.comment_count } Commented`,
          // append: `${post_.comment_count > 3 ? "+" + post_.comment_count - 3 : '' } Commented`,
          append: ` Commented`,
          icon: <CommentsIcon color="#5B5B5B" />,
          count: post_?.comment_count,
          users: post_?.comment_by,
          extra: Math.max((post_?.comment_by?.length ?? 0) - maxUserCount, 0),
          content: commentBy,
        },
        {
          // append: `${post_.like_count >10 ? "+" + post_.like_count/10 : "+" + post_.like_count } Agreed`,
          append: ` Agreed`,
          icon: <HandShakeIcon color="#037F29" />,
          count: post_?.like_count,
          users: post_?.liked_by,
          extra: Math.max((post_?.liked_by?.length ?? 0) - maxUserCount, 0),
          content: likedBy,
        },
        {
          // append: `${post_.dislike_count >10 ? "+" + post_.dislike_count/10 : "+" + post_.dislike_count } Disagreed`,
          append: ` Disagreed`,
          icon: <HandShakeIcon color="#E41C19" />,
          count: post_?.dislike_count,
          users: post_?.dislike_by,
          extra: Math.max((post_?.dislike_by?.length ?? 0) - maxUserCount, 0),
          content: dislikedBy,
        },
        {
          // append: `${post_.share_count >10 ? "+" + post_.share_count/10 : "+" + post_.share_count } Reposted`,
          append: ` Reposted`,
          icon: <RepostedIcon color="#5B5B5B" />,
          count: post_?.reposts_by?.length||post_?.repost?.share_count,
          users: post_?.reposts_by,
          extra: Math.max((post_?.reposts_by?.length ?? 0) - maxUserCount, 0),
          content: sharedBy,
        },
      ];
    }, [post, ps]);
    const toShow = useMemo(
      () => map?.sort?.((a, b) => b.count - a.count)?.[0],
      [map]
    );
    
    const showSuggested = useMemo(
      () => (map?.find?.((i) => i.count > 0) ? false : ps?.suggested),
      [map]
    );

    const users = useMemo(() => {
      if (myFirstDegreeConnections?.length) {
        return toShow?.users
          ?.map?.((u) => ({
            ...(u ?? {}),
            order: myFirstDegreeConnections?.find?.((u_) => u_?.id === u?.id)
              ?.id
              ? 0
              : 1,
          }))
          ?.sort((a, b) => a - b);
      }
      return toShow?.users ?? [];
    }, [toShow?.users, myFirstDegreeConnections]);

    const agreedUsers = map.find(item => item?.append === " Agreed")?.users ?? [];
    const disagreedUsers = map.find(item => item?.append === " Disagreed")?.users ?? [];
    const allCount = liked_by?.length + dislike_by?.length;
    const agreedCount = agreedUsers?.length;
    const disagreedCount = disagreedUsers?.length;
    const AllUsers = [...liked_by, ...dislike_by];
    useEffect(() => {
      if (!myFirstDegreeConnections || myFirstDegreeConnections?.length <= 0) {
        refetchMyFirstDegreeConnections?.();
      }
    }, []);

    const timerRef = useRef(null);
    // console.log(comment_by);

    const triggerRef = useRef(null);

    // Function to calculate position
    const getPopupPosition = () => {
      if (!triggerRef.current) return {};
      
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - triggerRect.bottom;
      const spaceAbove = triggerRect.top;
      
      // If more space below, show popup below
      if (spaceBelow >= 400 || spaceBelow > spaceAbove) {
        return {
          top: triggerRect.bottom + 8,
          left: triggerRect.left
        };
      }
      
      // Show popup above if more space there
      return {
        bottom: viewportHeight - triggerRect.top + 8,
        left: triggerRect.left
      };
    };

    if (pinned) {
      return (
        <HStack py={3} px={4} >
          {/* <Box
            display="flex"
            bg="#E4E6EB"
            w="28px"
            h="28px"
            justifyContent="center"
            boxShadow="2px 0px 6px 0px #00000033"
            alignItems="center"
            borderRadius="full"
          >
              <PindIcon color="rgba(0,0,0,0.3)" width="16" height="16" />
          </Box> */}
          <Circle
            bg="#D9D9D9"
            w="28px"
            h="28px"
            boxShadow="0px -5px 3p<MenuButton
            as={IconButton}
            icon={<HorizontalThreeDot />}
            sx={{
              borderRadius: '27px ',
              border: '1px solid #00000033',
              backgroundColor: 'transparent',
              color: 'inherit',
              _hover: {
                backgroundColor: 'white',
                color: 'inherit',
              },
              _focus: {
                backgroundColor: 'white',
                color: 'inherit',
              },
            }}
            onClick={(e) => {
              e.stopPropagation?.();
            }}
          >
            {children}
          </MenuButton>x 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
          >
            <PinnedIcon />
          </Circle>

          <HStack spacing={1}>
            <Text fontSize="sm" color="#828282">
              {'Pinned'}
            </Text>
          </HStack>
        </HStack>
      );
    }

    if (toShow.count <= 0) {
      return <></>;
    }

    

    const handleMouseEnter = () => {
      // Only open popup if there are users to show
      if (toShow?.users?.length > 0) {
        clearTimeout(timerRef.current);
        setIsPopupOpen(true);
      }
    };

    const handleMouseLeave = () => {
      timerRef.current = setTimeout(() => {
        setIsPopupOpen(false);
      }, 1000);
    };

    return (
      <>
        {showSuggested ? (
          <HStack p={4}>
            {/* <Box display="flex"
              bg="#E4E6EB"
              w="28px"
              h="28px"
              justifyContent="center"
              boxShadow="2px 0px 6px 0px #00000033"
              alignItems="center"
              borderRadius="full">
              <SuggestedPostIcon />
            </Box> */}
            <Circle
              bg="#D9D9D9"
              w="28px"
              h="28px"
              boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
            >
              <SuggestedIcon />
            </Circle>
            <Text>Suggested for you</Text>
          </HStack>
        ) : (
          <HStack py={3} px={4}>
            {/* <InsightPopover content={commentBy}> */}
            <Circle
              bg="#D9D9D9"
              w="28px"
              h="28px"
              boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
            >
              {toShow.icon}
            </Circle>
            <HStack 
              ref={triggerRef}
              spacing={0}
              // onMouseEnter={() => setIsPopupOpen(true)}
              // onMouseEnter={() => {
              //   // Only open popup if there are users to show
              //   if (toShow?.users?.length > 0) {
              //     setIsPopupOpen(true)
              //   }
              // }}
              // onMouseLeave={() => setIsPopupOpen(false)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <AvatarGroup color="#828282" size="sm" max={maxUserCount}>
                {users?.map?.((u, uInd) => (
                  <Avatar
                    as={Link}
                    href={
                      u?.name?.length > 0 ? '/c/' + u?.slug : '/p/' + u?.slug
                    }
                    key={uInd}
                    size="sm"
                    fontSize="10px"
                    src={u?.name?.length > 0 ? u?.image : getUserProfilePic(u)}
                    name={getUserFullName(u)}
                  />
                ))}
              </AvatarGroup>
              <Text fontSize="16px" color="#828282">
                {toShow.append}
              </Text>
            </HStack>
            {/* </InsightPopover> */}
          </HStack>
        )}
        {isPopupOpen && (
          <Box
          position="fixed"
          w="400px"
          bg="white"
          borderRadius="8px"
          boxShadow = "0px 0px 10px 0px #00000033"
          p={4}
          pt={0}
          zIndex={10}
          style={getPopupPosition()}
            minHeight="400px"
            onMouseEnter={() => clearTimeout(timerRef.current)}
            onMouseLeave={handleMouseLeave}
          >
            
            <Box position="relative" border="transparent">
            {(toShow?.append === " Agreed" || toShow?.append === " Disagreed") && <IconButton
              pos={"absolute"}
              right={0}
              top={"21px"}
              icon={<SimpleCloseIcon height="14px" width="14px" />}
              aria-label="Close"
              variant={'unstyled'}
              onClick={() => setIsPopupOpen(false)}
            />}
            <Divider mb={4} />
            { toShow?.append === " Agreed" || toShow?.append === " Disagreed" ? 
            <Tabs border={"transparent"}>
              <TabList>
                <Tab color={"#3D5A80"} fontWeight={400} fontSize={"15px"}>{`All ${allCount}`}</Tab>
                <Tab color={"#3D5A80"} fontWeight={400} fontSize={"15px"}><HandShakeIcon color="#037F29" /><Text ml="5px">{ agreedCount }</Text></Tab>
                <Tab color={"#3D5A80"} fontWeight={400} fontSize={"15px"}><HandShakeIcon color="#E41C19" /><Text ml="5px">{ disagreedCount }</Text></Tab>
              </TabList>
              <TabPanels>
                {/* All Users */}
                <TabPanel>
                  <VStack spacing={4} align="stretch">
                    {AllUsers.map((user, index) => (
                      <Flex key={index} alignItems="center" justifyContent="space-between">
                        <HStack spacing={3}>
                          <Box pos="relative">
                            <Avatar src={user?.profile_pic} name={getUserFullName(user)} />
                            <Box
                              pos="absolute"
                              bottom="0"
                              right="0"
                              borderRadius="full"
                              w="20px"
                              h="20px"
                              // background={toShow?.append === ' Agreed' ? "#037F29" : "#E41C19"}
                              background={
                                liked_by.some(likedUser => likedUser.id === user.id)
                                  ? "#037F29"  // Green for Agreed users
                                  : "#E41C19"  // Red for Disagreed users
                              }
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              border="2px solid white"
                            >
                              <HandShakeSimpleIcon
                                color="#ffffff"
                                width="12"
                                height="12"
                              />
                            </Box>
                          </Box>
                          {/* <Avatar src={user?.profile_pic} name={getUserFullName(user)} /> */}
                          <Text fontWeight="500" color="#5B5B5B" fontSize="15px">{getUserFullName(user)}</Text>
                        </HStack>
                        <HStack spacing={2}>
                          {currentUserInfo?.id === user?.id ? null :
                          !isFriend(user) ? <HStack>
                            {isHandshakeRequested?.(user) ? (
                              <Tooltip label="Withdraw Handshake">
                                <IconButton
                                  onClick={(e) =>
                                    handleHandshakeSendRevoke?.(user)
                                  }
                                  _hover={{ bg: 'gray.100' }}
                                  isLoading={handshakeLoading}
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#F5AEAD"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  
                                  variant="none"
                                />

                              </Tooltip>
                            ) : (
                              <Tooltip zIndex="99999999" label="Send Handshake Request">
                                <IconButton
                                  w={'41px'}
                                  _hover={{ bg: 'gray.100' }}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  onClick={(e) => handleHandshake?.(user)}
                                  // isLoading={handshakeLoading }
                                  isLoading={loadingButtonId === user.id}
                                  // w="70px"
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#94B096"
                                      // color={
                                      //   liked_by.some(likedUser => likedUser.id === user.id)
                                      //     ? "#037F29"  // Green for Agreed users
                                      //     : "#E41C19"  // Red for Disagreed users
                                      // }
                                      width="20"
                                      height="20"
                                    />
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack> : 
                          <Box
                            w={'41px'}
                            height={'41px'}
                            border={'1px solid #00000033'}
                            cursor="pointer"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={'50%'}
                            onClick={(e) => {
                              router.push(`/profile/message?user=${user?.matrix_user_id}`)
                            }}
                            _hover={{ bg: 'gray.100' }}
                          >
                            <ChatOutLined/>
                          </Box>
                          }
                        </HStack>
                      </Flex>
                    ))}
                  </VStack>
                </TabPanel>
                {/* Agree Users */}
                <TabPanel>
                  <VStack spacing={4} align="stretch">
                    {toShow?.append === ' Agreed' &&
                      toShow?.users.map((user, index) => (
                        <Flex key={index} alignItems="center" justifyContent="space-between">
                          <HStack spacing={3}>
                            <Avatar src={user?.profile_pic} name={getUserFullName(user)} />
                            <Text fontWeight="500" color="#5B5B5B" fontSize="15px">{getUserFullName(user)}</Text>
                          </HStack>
                          <HStack spacing={2}>
                          {/* <Circle
                            bg="#D9D9D9"
                            w="28px"
                            h="28px"
                            boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
                          >
                            <HandShakeIcon color="#037F29" />
                          </Circle> */}
                          {currentUserInfo?.id === user?.id ? null :
                          !isFriend(user) ? <HStack>
                            {isHandshakeRequested?.(user) ? (
                              <Tooltip label="Withdraw Handshake">
                                <IconButton
                                  onClick={(e) =>
                                    handleHandshakeSendRevoke?.(user)
                                  }
                                  _hover={{ bg: 'gray.100' }}
                                  isLoading={handshakeLoading}
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#F5AEAD"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  
                                  variant="none"
                                />

                              </Tooltip>
                            ) : (
                              <Tooltip zIndex="99999999" label="Send Handshake Request">
                                <IconButton
                                  w={'41px'}
                                  _hover={{ bg: 'gray.100' }}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  onClick={(e) => handshakeUser?.(user)}
                                  isLoading={handshakeLoading}
                                  // w="70px"
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#94B096"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack>:
                          <Box
                          w={'41px'}
                          height={'41px'}
                          border={'1px solid #00000033'}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={'50%'}
                          onClick={(e) => {
                            router.push(`/profile/message?user=${user?.matrix_user_id}`)
                          }}
                          _hover={{ bg: 'gray.100' }}
                        >
                          <ChatOutLined/>
                        </Box>
                          }
                          </HStack>
                        </Flex>
                      ))}
                  </VStack>
                </TabPanel>
                {/* Disagree Users */}
                <TabPanel>
                  <VStack spacing={4} align="stretch">
                    {
                      disagreedUsers.map((user, index) => (
                        <Flex key={index} alignItems="center" justifyContent="space-between">
                          <HStack spacing={3}>
                            <Avatar src={user?.profile_pic} name={getUserFullName(user)} />
                            <Text fontWeight="500" color="#5B5B5B" fontSize="15px">{getUserFullName(user)}</Text>
                          </HStack>
                          <HStack spacing={2}>
                          {currentUserInfo?.id === user?.id ? null :
                          !isFriend(user) ? <HStack>
                            {isHandshakeRequested?.(user) ? (
                              <Tooltip label="Withdraw Handshake">
                                <IconButton
                                  onClick={(e) =>
                                    handleHandshakeSendRevoke?.(user)
                                  }
                                  _hover={{ bg: 'gray.100' }}
                                  isLoading={handshakeLoading}
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#F5AEAD"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  
                                  variant="none"
                                />

                              </Tooltip>
                            ) : (
                              <Tooltip zIndex="99999999" label="Send Handshake Request">
                                <IconButton
                                  w={'41px'}
                                  _hover={{ bg: 'gray.100' }}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  onClick={(e) => handshakeUser?.(user)}
                                  isLoading={handshakeLoading}
                                  // w="70px"
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#94B096"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack> :
                          <Box
                          w={'41px'}
                          height={'41px'}
                          border={'1px solid #00000033'}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={'50%'}
                          onClick={(e) => {
                            router.push(`/profile/message?user=${user?.matrix_user_id}`)
                          }}
                          _hover={{ bg: 'gray.100' }}
                        >
                          <ChatOutLined/>
                        </Box>
                          }
                          </HStack>
                        </Flex>
                      ))}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs> : 
            <Box>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontWeight="400" fontSize="20px" color="#3D5A80" display="flex" justifyContent="center" width="100%">
                  {toShow?.append}
                </Text>
                <IconButton
                  display={"flex"}
                  icon={<SimpleCloseIcon height="14px" width="14px" />}
                  aria-label="Close"
                  variant={'unstyled'}
                  onClick={() => setIsPopupOpen(false)}
                />
              </Flex>
              <Box>
              <VStack spacing={4} align="stretch">
                    {
                      toShow?.users.map((user, index) => (
                        <Flex key={index} alignItems="center" justifyContent="space-between">
                          <HStack spacing={3}>
                            <Avatar src={user?.profile_pic} name={getUserFullName(user)} />
                            <Text fontWeight="500" color="#5B5B5B" fontSize="15px">{getUserFullName(user)}</Text>
                          </HStack>
                          <HStack spacing={2}>
                          {currentUserInfo?.id === user?.id ? null :
                          !isFriend(user) ? <HStack>
                            {isHandshakeRequested?.(user) ? (
                              <Tooltip label="Withdraw Handshake">
                                <IconButton
                                  onClick={(e) =>
                                    handleHandshakeSendRevoke?.(user)
                                  }
                                  _hover={{ bg: 'gray.100' }}
                                  isLoading={handshakeLoading}
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#F5AEAD"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  
                                  variant="none"
                                />

                              </Tooltip>
                            ) : (
                              <Tooltip zIndex="99999999" label="Send Handshake Request">
                                <IconButton
                                  w={'41px'}
                                  _hover={{ bg: 'gray.100' }}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  onClick={(e) => handshakeUser?.(user)}
                                  isLoading={handshakeLoading}
                                  // w="70px"
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#94B096"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack> :
                          <Box
                          w={'41px'}
                          height={'41px'}
                          border={'1px solid #00000033'}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={'50%'}
                          onClick={(e) => {
                            router.push(`/profile/message?user=${user?.matrix_user_id}`)
                          }}
                          _hover={{ bg: 'gray.100' }}
                        >
                          <ChatOutLined/>
                        </Box>
                          }
                          </HStack>
                        </Flex>
                      ))}
                  </VStack>
              </Box>
            </Box>
          
            }
            </Box>
          </Box>
        )}
      </>
    );
  }
);
